/*CSS variables section*/
:root {
  /*COLORS*/
  background-color: #101010;
  --background: #1f1f1f;
  --announcementBox: grey;
  --mainBox: #ffc02a;
  --mainBoxHighlight: grey;
  --highlightedText: #ffc02a;
  --btnBackground: #b98303;
  --sponsorHighlight: grey;
  --grey: #ced4da;
  --navBar: #101010;
  --navBarText: #ffc02a;
  /*--navBar: #ffc02a;
  --navBarText: white;*/
  --title: #fff7f0;
  --text: #fff7f0;
  --border: grey;
  --videoCover: #00000081;
}

/*Extra Large screens - Extra large desktops*/
.header {}


@font-face {
  font-family: "header";
  src: url('/Fonts/BarlowCondensed-ExtraBold.ttf');
}

@font-face {
  font-family: "text";
  src: url('/Fonts/RobotoCondensed-Regular.ttf');
}

#background-video {
  width: 100vw;
  height: 900px;
  object-fit: cover;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

#iframeVideo {
  width: 78vw;
  height: 50vw;
  object-fit: cover;
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.heeder-large-screen {
  display: flex !important;
  justify-content: space-evenly !important;
  color: var(--highlightedText);
  font-size: 18px !important;
  position: fixed;
  z-index: 1;
  top: 0;
}

.heeder-large-screen-secondary {
  display: block !important;
  justify-content: space-evenly !important;
  color: var(--highlightedText);
  font-size: 18px !important;
  position: fixed;
  z-index: 1;
  top: 0;
}

#navDemo {
  background-color: var(--navBar);
  z-index: 1;
  display: none;
  position: fixed;
  width: 100%;
  top: 40px;
}

#navDemo a {
  font-size: px !important;
  color: var(--navBarText);
}

.hero {
  height: 900px;
  /*background-image: url("BCR2.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position:center;
  background-color: #000015; 
  */


}

.hero-description {
  height: 900px;
  background-color: var(--videoCover);
  display: flex;
}

.hero-text {
  width: 70% !important;
  margin: auto !important;
  flex-wrap: wrap;
  font-family: "text";
}


.hero-text p {
  color: var(--text);
  width: 50%;
  text-shadow:
    -1px -1px 0 #000,
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000;
  font-family: "text";
  font-size: 20px;
}

.hero-title {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 70px;
  color: var(--highlightedText);
  text-shadow:
    -1px -1px 0 #000,
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000;
  text-align: left;
  font-family: "header";

}

.social {
  width: 100%;
}

.icons {
  font-size: 40px;
  letter-spacing: 5px;
  margin-right: 15px;

}

main {
  width: 80% !important;
  margin: auto !important;
  align-items: center;
  font-family: "text";
}

.title {
  margin-inline: auto;
  color: var(--title);
  font-family: "header";
  /*
  text-shadow:
  -1px -1px 0 #ffb500,
  1px -1px 0 #ffb500,
  -1px 1px 0 #ffb500,
  1px 1px 0 #ffb500;
  padding-inline:30px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top-left-radius:20px;
  border-top-right-radius:20px;
  background-color: var(--highlightedText);
  */
  font-weight: 500;
  font-size: 40px;
  margin-bottom: 10px;
  margin-top: 90px;
  /*margin:50px 0px;*/
  /*width:fit-content;*/
  text-align: center;
  text-transform: uppercase;

}

.sponsor-logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

h3 {
  color: var(--text);
  text-align: center;
  font-family: "text";
}

.sponsor {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  /* background-color:	rgba(0, 0, 47,0.3); */
  justify-content: space-evenly;
}

.sponsor-list {
  margin-top: 20px;
  margin-bottom: 50px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s ease;
  font-family: "text";
}

.sponsor-list img {
  border: 1px solid var(--border);
  background: var(--background);
  box-shadow: 0 8px 40px 0 var(--announcementBox);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  border-radius: 10px;
  border: 4px solid var(--border);

}

.sponsor-list:hover {
  transform: scale(1.05);

}

h3 {
  color: var(--text);
  text-align: center;
  font-family: "text";
}

.announcement {
  display: flex;

  flex-wrap: wrap;
  align-items: center;
  /* background-color:	rgba(0, 0, 47,0.3); */
  justify-content: space-evenly;
}

.announcement-list {
  margin: 10px;
  ;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--announcementBox);
  box-shadow: 0 8px 40px 0 var(--announcementBox);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  font-family: "text";
}

.store {
  margin-top: 20px;
}

.game-image {
  width: 100%;

}

.game-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.boxed {
  margin-top: 20px !important;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.boxed-list {
  margin: 10px;
  padding: 10px;
  overflow: hidden;
  border-top: none;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-bottom: 1px solid var(--mainBox);
  color: var(--text);
  font-family: "text";

}

.store-text-description {
  font-family: "text";

}

h4 {
  color: var(--text);
  font-family: "text";
  /*font-weight: 300;*/
}

.event-hero {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background: var(--announcementBox);
  justify-content: space-evenly;
  align-items: center;
  padding: 10px;
  margin-top: 10px;
  border-radius: 10px;
  font-family: "text";

}

.event-text {
  width: 80%;
  color: var(--text);

}

.event-title {
  font-size: 40px;
  text-transform: uppercase;
  font-family: "header";
  font-weight: 500;


}

.event-image {
  width: 400px;
  height: 500px;
}

.event-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.event-text p {
  color: var(--text);
}

.contact {


  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.contact-list {
  margin-top: 15px !important;
  color: var(--text);
  font-family: "text";
}

h5 {
  color: var(--text);
}

span {
  padding-right: 10px;
  font-size: smaller;
}

.email-contact {
  width: 80%;
  color: gray;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.mainTeam {
  width: 90% !important;
  margin: auto !important;
  font-family: "text";
  padding-top: 50px;
}

.team {
  width: 100%;
  display: grid;
  grid-template-areas:
    'photo main main main main main main main main main main main main main main main main';
  /* this amount of mains is actually necessary */
  gap: 10px;
  background: var(--announcementBox);
  padding: 10px;
  margin-top: 30px;
  border-radius: 100px;

}

.team-member-text {
  grid-area: main;
  padding-right: 10px;
  padding-bottom: 20px;
  width: 100%;
  font-family: "text";
  text-align: left;
  border-radius: 100px;
  background-color: var(--background);
  padding-left: 100px;
  padding-right: 35px;
}

.team-member-text p {
  color: var(--text);
  font-family: "text";
  font-size: 20px;

}

.team-member-text-img {
  grid-area: photo;
}

.team-member-text-img img {
  border-radius: 100px;
  height: 430px;
}

.role {
  text-align: left;
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 40px;
}

.name {
  text-align: left !important;
  margin-top: 15px;
  margin-bottom: 0px;
}

.people {
  width: 250px;
  height: 125px;
}

.people img {
  height: 300px;
}

.people-list {
  padding-bottom: 200px;
}

.buttonOnBackground {
  -webkit-filter: brightness(100%);
  font-size: large;
  font-family: "text";
}

.buttonOnBackground:hover {
  -webkit-filter: brightness(70%);
  transform: scale(.95);
}

table {
  font-family: "text";
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid var(--text);
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: var(--sponsorHighlight);
}

/*General CSS and CSS reset*/

html {
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
}

.text-rainbow-animation {
  background-image:
    linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet, red);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: rainbow-animation 100s linear infinite;
}

@keyframes rainbow-animation {
  to {
    background-position: 4500vh;
  }
}








/* ____________________________________________________________________*/
/*MEDIA QUERIES*/

/*Large screens - Desktop*/
@media screen and (max-width: 1650px) {
  .hero {
    height: 700px !important;
    ;
    /*background-image: url("TeamPhoto.JPG")*/
    ;
  }

  #background-video {
    width: 100%;
    height: 700px;
    object-fit: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
  }

  .hero-description {
    height: 100%;
  }

  .hero-title {
    font-size: 50px;
  }
}

/*medium screens - Laptops*/
@media screen and (max-width: 1150px) {
  main {
    width: 90% !important;
    margin: auto !important;
  }

  .hero {
    height: 900px !important;
    ;
    /*background-image: url("TeamPhoto.JPG")*/
    ;
  }

  #background-video {
    width: 100%;
    height: 900px;
    object-fit: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
  }

  .hero-description {
    height: 100%;
  }

  .hero-text p {
    width: 100%;
  }

  .hero-title {
    font-size: 50px;
    text-align: center;
  }

  .team-member-text p {
    font-size: 18px;
  }

  .team-member-text {
    padding-left: 65px;
  }

  .people {
    width: 200px;
  }

}

/*Small screens - tablets*/
@media screen and (max-width: 768px) {
  .heeder-large-screen {
    display: block !important;
  }

  header a {
    font-size: 14px;
  }

  .hero {
    height: 600px !important;
    /*background-image: url("TeamPhoto.JPG");*/
  }

  #background-video {
    width: 100%;
    height: 600px;
    object-fit: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
  }

  .hero-description {
    width: 100%;
  }

  .hero-title {
    font-size: 45px;
    ;
    padding-top: 10px;
  }

  .hero-text p {
    width: 100%;
    ;
    font-size: 15px;
  }

  main {
    width: 100% !important;
  }

  .team {
    grid-template-areas:
      'photo'
      'main';
  }

  .team-member-text {
    padding-left: 50px;
    padding-right: 35px;
  }

  .name {
    margin-top: 5px;
  }

  .people {
    width: 150px;
  }

  .people img {
    height: 200px;
  }

  .people-list {
    padding-bottom: 100px;
  }

  .buttonOnBackground {
    font-size: medium;
  }
}

/*Extra small screens - phones*/
@media screen and (max-width: 490px) {
  .heeder-large-screen {
    display: block !important;
  }

  .hero {
    height: 600px !important;
    /*background-image: url("TeamPhoto.JPG");*/
  }

  #background-video {
    width: 100vw;
    height: 600px;
    object-fit: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
  }

  .hero-description {
    height: 100%;
  }

  .hero-title {
    font-size: 40px;
    ;
  }

  .hero-text p {
    width: 100%;
    ;
    font-size: 13px;
  }

  .team {
    justify-items: center;
  }

  .team-member-text p {
    font-size: 15px;
    padding-right: 8px;
  }

  .people {
    width: 100px;
  }

  .people img {
    height: 150px;
  }

  .people-list {
    padding-bottom: 50px;
  }
}

/*extra Small screens - fold phones*/
@media screen and (max-width: 390px) {
  .heeder-large-screen {
    display: block !important;
  }

  header a {
    font-size: 14px;
  }

  .hero {
    height: 600px !important;
    /*background-image: url("TeamPhoto.JPG");*/
  }

  #background-video {
    width: 100%;
    height: 600px;
    object-fit: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
  }

  .hero-description {
    width: 100%;
  }

  .hero-title {
    font-size: 40px;
    ;
    padding-top: 10px;
  }

  .hero-text p {
    width: 100%;
    ;
    font-size: 12px;
  }

}